
















import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import MemoPhraseSelector from '@/components/organisms/MemoPhraseSelector.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ModalBase,
    MemoPhraseSelector,
    ButtonBase,
  },
})
export default class ModalTeachingMemo extends Mixins(ModalBaseMethod) {
  private memoPhrase: number | null = null

  @Prop()
  supporterMemo!: { id: null | number; lessonUserId: null | number; memo: string }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    let permitted = true

    if (!this.previewSupporterMemo() || this.previewSupporterMemo() === '') {
      permitted = false
    } else if (this.previewSupporterMemo().length > 21845) {
      permitted = false
    }

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private previewSupporterMemo(): string {
    return this.supporterMemo.memo + (this.memoPhrase ? this.memoPhrase : '')
  }

  private async updateAndHide() {
    await this.saveMemo()
    this.memoPhrase = null
    this.hide()
  }

  private async saveMemo(): Promise<void> {
    if (!this.previewSupporterMemo() || this.previewSupporterMemo() === '') {
      alert('メモを入力してください')
      return
    } else if (this.previewSupporterMemo().length > 21845) {
      alert('メモの文字数は21845文字までです')
      return
    }

    if (!this.supporterMemo.id) {
      await Vue.prototype.$http.httpWithToken
        .post('/supporter_memos', {
          lessonUserId: this.supporterMemo.lessonUserId,
          memo: this.previewSupporterMemo(),
        })
        .then((res: any) => {
          this.supporterMemo.id = res.data.id
          alert('メモを保存しました。')
        })
        .catch(() => {
          alert('エラーが発生しました。ページを更新してください。')
        })
    } else {
      await Vue.prototype.$http.httpWithToken
        .patch(`/supporter_memos/${this.supporterMemo.id}`, { memo: this.previewSupporterMemo() })
        .then(() => {
          alert('メモを保存しました。')
        })
        .catch(() => {
          alert('エラーが発生しました。ページを更新してください。')
        })
    }
  }
}
