import { Component, Vue } from 'vue-property-decorator'
import Lesson from '@/mixins/action-cable/LessonAbstract'

@Component
export default class LessonTeacher extends Lesson {
  public async beforeMount() {
    await this.AbstractBeforeMountBefore()

    Vue.prototype.$logger.info('-- LessonTeacher mounted')
    const lessonId = this.$route.params.id

    if (!lessonId) return
    this.$store.commit('lessonActionCable/setLessonId', lessonId)

    this.AbstractBeforeMountAfter()
  }
}
