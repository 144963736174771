





import { Component, Prop, Vue } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'

@Component({
  components: {
    LabelBase,
  },
})
export default class LabelPointTransition extends Vue {
  private sign(): string {
    return this.isAdditional() ? '+' : ''
  }

  private isAdditional(): boolean {
    return this.point > 0
  }

  @Prop()
  point!: number

  @Prop()
  radius?: string

  get colortype(): string {
    return this.isAdditional() ? 'alert' : 'information'
  }
}
