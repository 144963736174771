




















import { Component, Prop, Mixins } from 'vue-property-decorator'
import LabelPointTransition from '@/components/molecules/LabelPointTransition.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    LabelPointTransition,
    ModalBase,
  },
})
export default class ModalPointHistory extends Mixins(ModalBaseMethod) {
  @Prop()
  pointHistory!: {}
}
