import { render, staticRenderFns } from "./LabelPointTransition.vue?vue&type=template&id=1341cea8&scoped=true&lang=pug&"
import script from "./LabelPointTransition.vue?vue&type=script&lang=ts&"
export * from "./LabelPointTransition.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1341cea8",
  null
  
)

export default component.exports